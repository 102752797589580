<template>
  <!-- 内容 -->
  <div class="help_content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
<!--      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{ '帮助中心' }}</span></el-breadcrumb-item>-->
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{ lastPage=='lastPage'?'上一页':'帮助中心' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">公告中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice_list" style="margin-top: 30px">
      <div v-for="(item, i) in noticeMsgList" style="margin-bottom: 30px;cursor: pointer;text-align: left" @click="gotoNotice(item)">
        <span style="display: block">{{ item.title }}</span>
        <span style="display: block !important;margin-top: 8px;color: #72777E;">{{ item.send_time }}</span>
      </div>
    </div>
    <div style="display: block">
      <my_video v-if="IsShow" ref="child" :src="src_url" :showVideo="showVideo" :showVideo_m="showVideo_m" :poster_url="poster_url" :showBtn1="showBtn"></my_video>
    </div>
  </div>
</template>

<script>
// import My_video from "../common/my_video";
import { getNoticeMsgList } from "../../server/helper.js"
export default {
  name: "help_content_one",
  components: {
    // My_video
  },
  data(){
    return{
      common_problem: [
        {index: 1, title: 'UPlus产品介绍'},
        {index: 2, title: '专属用户介绍'},
        {index: 3, title: '修改策略仓位说明'},
        {index: 4, title: '止损说明' },
        {index: 5, title: '点卡和燃料费说明' },
        {index: 6, title: '交易账户浮亏说明' },
      ],
      auto_service: [
        {index:1,url: require('../../assets/img/help/newbie.svg'), name: '新手教程'},
        {index:2,url: require('../../assets/img/help/play.svg'), name: '玩转机器人'},
        {index:3,url: require('../../assets/img/help/business.svg'), name: '商务合作'},
        {index:4,url: require('../../assets/img/help/protocol.svg'), name: '协议条款'},
        {index:5,url: require('../../assets/img/help/circular.svg'), name: '公告中心'},
        {index:6,url: require('../../assets/img/help/problem.svg'), name: '问题反馈'},
      ],
      new_activities: [
        {index: 6, page: '5_1_6', brief: '关于返佣系统的调整和间接推荐奖下架公告', date: '2022-06-07'},
        {index: 5, page: '5_1_5', brief: '关于清退中国大陆地区存量用户的再次公告', date: '2022-05-31'},
        {index: 4, page: '5_1_4', brief: '关于币安下架BTT含有此代币的币安机器人需重新设置的公告', date: '2022-04-01'},
        {index: 3, page: '5_1_3', brief: '关于因币安的挂单限制不建议在不同策略中设置相同的交易对', date: '2022-04-01'},
        {index: 2, page: '5_1_2', brief: '关于充值功能及补充燃料费方式调整的公告', date: '2022-03-31'},
        {index: 1, page: '5_1_1', brief: '关于欧易部分币对交易货币数量精度调整的公告', date: '2022-03-24'},
      ],
      showVideo: false,
      showVideo_m: false,
      src_url: '',
      poster_url: '',
      showBtn: false,
      IsShow: false,
      noticeMsgList: [],
      index: '',
      lastPage: '',
    }
  },

  mounted() {
    if (this.$util._isMobile()) {
      this.showVideo = false
      this.showVideo_m = false
    } else {
      this.showVideo = false
      this.showVideo_m = false
    }
    this.index = this.$route.query.titleNum
    this.lastPage = this.$route.query.lastPage
    this.getNoticeMsgList()
  },

  methods: {
    gotoContentOne(item){
      if (item.index != 1){
        if (item.index == 6 && this.$util._isMobile()){
          this.$router.push('/feedBack')
        } else if(item.index == 5){
          this.$router.push('/helpTitle?titleNum='+'5_1')
        }else {
          this.$message('程序员正在努力完善此功能！')
          return;
        }
      }else{
        this.$router.push('/helpTitle?titleNum='+item.index)
      }
    },
    goto(item){
      if (item.index == 5){
        this.IsShow = true
        this.$nextTick(()=>{
          this.src_url = item.src_url
          this.poster_url = item.cover
          this.showVideoM()
        })
      } else {
        this.$router.push('/helpContent?titleNum='+item.index+'&home=home')
      }
    },
    gotoNotice(item) {
      this.$router.push('helpNoticeContent?home=list'+'&noticeId=' + item.id + '&lastPage='+ this.lastPage)
    },
    showVideoM(){
      if (this.$util._isMobile()){
        this.showVideo = false
        this.showVideo_m = true
        this.$refs.child.showVideoM()
      } else{
        this.showVideo = true
        this.showVideo_m = false
        this.showBtn = false
        this.$refs.child.open_video()
      }
    },

    getNoticeMsgList(){
      getNoticeMsgList().then(res =>{
        if(!res) return
        if(res.success){
          if (res.data.list){
            for (var i = 0; i < res.data.list.length; i++) {
              if (i<=5){
                this.noticeMsgList.push(res.data.list[i])
              }
            }
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    goback(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
span{
  color: #1E2329;
}

@media screen and (max-width: 900px){
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }
  .content_list {
    column-count: 1;
  }
  .content_list span{
    color: #464255;
    font-size: 14px;
  }
  .content_title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    display: block;
  }
  .auto_list{
    column-count: 3;
    text-align: center;
  }
  .notice_list{
    column-count: 1;
    text-align: center;
  }
  .notice_list span:nth-child(1){
    font-size: 14px;
  }
  .notice_list span:nth-child(2){
    font-size: 12px;
  }
  .el-container{
    display: block;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 90%;
    height: 230px;
    text-align: center;
  }

  .help_content_top_left{
    margin-top: 20px;
  }
  .help_content_bg{
    text-align: center;
  }
}
@media screen and (min-width: 900px){
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    color: #464255;
    width: 30%;
  }

  .auto_list{
    column-count: 6;
    text-align: center;
  }

  .notice_list{
    /*column-count: 2;*/
    /*text-align: center;*/
  }

  .notice_list div{
    display: inline-block !important;
    width: 50%;
  }

  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 14px;
  }

  .help_content {
    width: 1200px;
    min-height: 795px;
    margin: 50px auto 0;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 380px;
    height: 260px;
    margin-right: 30px;
  }

  .help_content_top_left{
    margin-left: 30px;
  }

  .help_content_bg{
    text-align: unset;
  }
}
</style>
